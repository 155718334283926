import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";
//pages
import AuthPage from "../pages/auth/AuthPage";
import OrgsPage from "../pages/orgs/orgs/OrgsPage";
import OrgsCreatePage from "../pages/orgs/orgsCreate/OrgsCreatePage";
import Notfound from "../pages/notfound/Notfound";
import CatalogPage from "../pages/catalog/catalog/CatalogPage";
import CatalogCategoryPage from "../pages/catalog/catalogCategory/CatalogCategoryPage";
import CreatePlatePage from "../pages/catalog/createPlate/CreatePlatePage";
import StoriesPage from "../pages/stories/StoriesPage";
import ClientsPage from "../pages/clients/ClientsPage";
import AppOrdersPage from "../pages/orders/AppOrdersPage";
import SiteOrdersPage from "../pages/orders/SiteOrdersPage";
import AppRevsPage from "../pages/revs/AppRevsPage";
import SiteRevsPage from "../pages/revs/SiteRevsPage";
import OrdersPage from "../pages/orders/OrdersPage";
import StatPage from "../pages/stat/StatPage";
import BasketPage from "../pages/basket/BasketPage";
import IntegrPage from "../pages/integr/IntegrPage";
import SettingsPage from "../pages/settings/SettingsPage";
import CheckAuth from "../hoc/ChekAuth";
import EditPlatePage from "../pages/catalog/createPlate/EditPlatePage";
import Sidebar from "../components/Sidebar/Sidebar";
import HeaderProfile from "../components/HeaderProfile/HeaderProfile";
import Header from "../components/Header/Header";
import { useLocation } from "react-router-dom";
import OrgsNewPage from "../pages/orgs/orgsCreate/OrgsNewPage";
import { useEffect } from "react";
import EditPlateNew from "../pages/catalog/createPlate/EditPlateNew";
import RatesetPage from "../pages/rateset/RatesetPage";
import RevsPage from "../pages/revs/RevsPage";
import ReservPage from "../pages/reserv/ReservPage";
import TrashPage from "../pages/trash/TrashPage";
import checkDomain from "../funcs/checkDomain";
import CheckRole from "../hoc/CheckRole";
import StoriesPageYm from "../pages/stories-yam/StoriesPage";
import AddClientPage from "../pages/clients/AddClient";
import {RootPage as SitesModule} from "../modules/Sites/containers/RootPage";
import checkDom from "../funcs/checkDom";
import VacanciesPage from "../pages/vacancies/VacanciesPage";
import AddVacancyPage from "../pages/vacancies/AddVacancyPage";
import QAPage from "../pages/q-a/QAPage";
import AddQAPage from "../pages/q-a/AddQAPage";
import ArticlesPage from "../pages/articles/ArticlesPage";
import AddArticlePage from "../pages/articles/AddArticlePage";
import PromotionsPage from '../pages/promotions/PromotionsPage';
import AddPromotionPage from '../pages/promotions/AddPromotionPage';
import PayDeliveryPage from "../pages/PayDelivery/PayDeliveryPage";
import AddPayDeliveryPage from "../pages/PayDelivery/AddPayDeliveryPage";
import Bonuses from "../pages/bonuses/Bonuses";
import AddBonuses from "../pages/bonuses/AddBonuses";
import Partnership from "../pages/partnership/Partnership";
import AddPartnership from "../pages/partnership/AddPartnership";
import Banners from '../pages/banners/Banners';
import NewsPage from "../pages/news/NewsPage";
import NavigationPage from "../pages/navigation/NavigationPage";
import AddNavigationPage from "../pages/navigation/AddNavigationPage";
import AddNewsPage from "../pages/news/AddNewsPage";
import DeliveryRules from "../pages/deliveryRule/DeliveryRules";
import AddConditions from "../pages/PayDelivery/AddConditions";
import CatalogHits from "../pages/catalogHits/CatalogHits";
import PlateHits from "../pages/plateHits/PlateHits";
import Accessibility from "../pages/accessibility/Accessibility";
import Users from '../pages/users/Users';
import Loyalty from "../pages/loyalty/Loyalty";
import AddLoyalty from "../pages/loyalty/AddLoyalty";


const App = () => {
    const loc = useLocation()

    useEffect(() => {
        checkDomain()
    }, [])

    return (
        <>
            <Helmet>
                {/* <link rel="icon" href={window.location.origin === 'https://bao.gscore.ru' ? "%PUBLIC_URL%/logo-bao.png" : "%PUBLIC_URL%/logo.png"} /> */}
                {window?.location?.origin === 'https://bao.gscore.ru' && (
                    <link rel="icon" href={window?.location.origin + `/logo-bao.png`} />
                )}
                {window?.location?.origin !== 'https://bao.gscore.ru' && (
                    <link rel="icon" href={window.location.origin + '/logo.png'}/>
                )}
                {/* <link rel="icon" href={window.location.origin + '/sell-logo.png'}/> */}
            </Helmet>
            {
                loc.pathname == '/auth' ? (
                    <Header/>
                ) : (
                    <HeaderProfile/>
                )
            }
            {
                loc.pathname != '/auth' ? (
                    <Sidebar updateCat/>
                ) : null
            }
            <Routes>
                <Route path="/" element={<CheckAuth><CheckRole><OrgsPage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations" element={<CheckAuth><CheckRole><OrgsPage/></CheckRole></CheckAuth>}/>
                <Route path="/reservations" element={<CheckAuth><CheckRole><ReservPage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog" element={<CheckAuth><CheckRole><CatalogPage/></CheckRole></CheckAuth>}/>
                <Route path="/loyalty" element={<CheckAuth><CheckRole><Loyalty/></CheckRole></CheckAuth>}/>
                <Route path="/add-loyalty" element={<CheckAuth><CheckRole><AddLoyalty/></CheckRole></CheckAuth>}/>
                {window.location.origin === 'http://localhost:3000' || 
                window.location.origin === 'https://uzgen.gscore.ru' ||
                 window.location.origin === 'https://mama.gscore.ru' || 
                 window.location.origin === 'https://test1.gscore.ru' || 
                 window.location.origin === 'https://pizzeria.gscore.ru' || 
                 window.location.origin === 'https://kekeev.gscore.ru' || 
                 window.location.origin === 'https://paulbaker.gscore.ru'||
                 window.location.origin === 'https://darvin.gscore.ru'||
                 window.location.origin === 'https://iiko.gscore.ru' ? 
                <>
                    <Route path="/categories-hits" element={<CheckAuth><CheckRole><CatalogHits/></CheckRole></CheckAuth>}/>
                    <Route path="/plates-hits" element={<CheckAuth><CheckRole><PlateHits/></CheckRole></CheckAuth>}/>
                </>  : null}
                <Route path="/site/*" element={<SitesModule />} />

                
                <Route path="/statistic" element={<CheckAuth><CheckRole><StatPage/></CheckRole></CheckAuth>}/>
                <Route path="/basket" element={<CheckAuth><CheckRole><BasketPage/></CheckRole></CheckAuth>}/>
                <Route path="/integr" element={<CheckAuth><CheckRole><IntegrPage/></CheckRole></CheckAuth>}/>
                <Route path="/admin-users" element={<CheckAuth><CheckRole><Users/></CheckRole></CheckAuth>}/>
                <Route path="/allsettings" element={<CheckAuth><CheckRole><SettingsPage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/createPlate" element={<CheckAuth><CheckRole><CreatePlatePage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/:subcategoryId/createPlate" element={<CheckAuth><CheckRole><CreatePlatePage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/editPlate/:plateId" element={<CheckAuth><CheckRole><EditPlatePage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/editPlate/:plateId/now" element={<CheckAuth><CheckRole><EditPlateNew/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/:subcategoryId/editPlate/:plateId" element={<CheckAuth><CheckRole><EditPlatePage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/:subcategoryId/editPlate/:plateId/now" element={<CheckAuth><CheckRole><EditPlateNew/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId" element={<CheckAuth><CheckRole><CatalogCategoryPage/></CheckRole></CheckAuth>}/>
                <Route path="/catalog/:categoryId/:subcategoryId" element={<CheckAuth><CheckRole><CatalogCategoryPage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/:brandId" element={<CheckAuth><CheckRole><OrgsPage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/:brandId/create" element={<CheckAuth><CheckRole><OrgsCreatePage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/:brandId/:orgId" element={<CheckAuth><CheckRole><OrgsCreatePage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/:brandId/:orgId/now" element={<CheckAuth><CheckRole><OrgsNewPage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/create" element={<CheckAuth><CheckRole><OrgsCreatePage/></CheckRole></CheckAuth>}/>
                <Route path="/organizations/edit" element={<CheckAuth><CheckRole><OrgsCreatePage/></CheckRole></CheckAuth>}/>
                <Route path="/ratingSettings" element={<CheckAuth><CheckRole><RatesetPage/></CheckRole></CheckAuth>}/>
                
                <Route path="/trash" element={<CheckAuth><CheckRole><TrashPage/></CheckRole></CheckAuth>}/>


                            <Route path="/revs-app" element={<CheckAuth><AppRevsPage/></CheckAuth>}/>
                            <Route path="/revs-site" element={<CheckAuth><SiteRevsPage/></CheckAuth>}/>
                <Route path="/revs" element={<CheckAuth><RevsPage/></CheckAuth>}/>
                <Route path="/clients" element={<CheckAuth><ClientsPage/></CheckAuth>}/>
                <Route path="/clients/create" element={<CheckAuth><AddClientPage /></CheckAuth>}/>
                {window.location.origin === 'https://iiko.gscore.ru' || 
                window.location.origin === 'https://mama.gscore.ru' || 
                window.location.origin === 'https://test1.gscore.ru' || 
                window.location.origin === 'https://paulbaker.gscore.ru'|| 
                window.location.origin === 'https://darvin.gscore.ru'|| 
                window.location.origin === 'https://iiko.gscore.ru' || 
                window.location.origin === 'https://tigrus.gscore.ru' || 
                window.location.origin === 'https://testjonny.gscore.ru' || 
                window.location.origin === 'https://uzgen.gscore.ru' || 
                window.location.origin === 'https://kekeev.gscore.ru' || 
                window.location.origin === 'https://pizzeria.gscore.ru' || 
                window.location.origin === 'https://osaka.gscore.ru' || 
                window.location.origin === 'http://localhost:3000' ? (
                <>
                            <Route path="/orders-app" element={<CheckAuth><AppOrdersPage/></CheckAuth>}/>
                            <Route path="/orders-site" element={<CheckAuth><SiteOrdersPage/></CheckAuth>}/>
                            <Route path="/vacancies" element={<CheckAuth><VacanciesPage/></CheckAuth>}/>
                            <Route path="/vacancies/create" element={<CheckAuth><AddVacancyPage/></CheckAuth>}/>
                            <Route path="/q-a" element={<CheckAuth><QAPage/></CheckAuth>}/>
                            <Route path="/q-a/create" element={<CheckAuth><AddQAPage/></CheckAuth>}/>
                            <Route path="/articles" element={<CheckAuth><ArticlesPage/></CheckAuth>}/>
                            <Route path="/articles/create" element={<CheckAuth><AddArticlePage/></CheckAuth>}/>
                            <Route path="/promotions" element={<CheckAuth><PromotionsPage/></CheckAuth>}/>
                            <Route path="/promotions/create" element={<CheckAuth><AddPromotionPage/></CheckAuth>}/>
                            <Route path="/pay-delivery" element={<CheckAuth><PayDeliveryPage/></CheckAuth>}/>
                            <Route path="/pay-delivery/create" element={<CheckAuth><AddPayDeliveryPage/></CheckAuth>}/>
                            <Route path="/pay-delivery/conditions" element={<CheckAuth><AddConditions/></CheckAuth>}/>
                            <Route path="/bonuses-program" element={<CheckAuth><Bonuses/></CheckAuth>}/>
                            <Route path="/bonuses-program/create" element={<CheckAuth><AddBonuses/></CheckAuth>}/>
                            <Route path="/partnership" element={<CheckAuth><Partnership/></CheckAuth>}/>
                            <Route path="/partnership/create" element={<CheckAuth><AddPartnership/></CheckAuth>}/>
                            <Route path="/news" element={<CheckAuth><NewsPage/></CheckAuth>}/>
                            <Route path="/news/create" element={<CheckAuth><AddNewsPage/></CheckAuth>}/>
                            <Route path="/navigation" element={<CheckAuth><NavigationPage/></CheckAuth>}/>
                            <Route path="/navigation/create" element={<CheckAuth><AddNavigationPage/></CheckAuth>}/>
                            <Route path="/delivery-rules" element={<CheckAuth><DeliveryRules/></CheckAuth>}/>
                            <Route path="/accessibility" element={<CheckAuth><Accessibility/></CheckAuth>}/>
                        </>
                        ) : null 
                }
                <Route path="/banners" element={<CheckAuth><Banners/></CheckAuth>}/>
                <Route path="/orders" element={<CheckAuth><OrdersPage/></CheckAuth>}/>
                <Route path="*" element={<CheckAuth><Notfound/></CheckAuth>}/>
                <Route path="/auth" element={<AuthPage/>}/>


                <Route path="/stories" element={<CheckAuth><CheckRole><StoriesPage/></CheckRole></CheckAuth>}/>
                <Route path="/stories-ym" element={<CheckAuth><StoriesPageYm/></CheckAuth>}/>
            </Routes>
        </>
        
    )
}

export default App;