import './OrgsCreatePage.scss';
import { DatePicker, Modal, Row, Col, message, Tabs, notification, Select } from 'antd';
import Pl from '../../../components/Pl/Pl';
import Button from '../../../components/Button/Button';
import {Button as Btn} from 'antd';
import Input from '../../../components/Input/Input';
import Text from '../../../components/Text/Text';
import Checkbox from '../../../components/Checkbox/Checkbox';
import DropCollapse from '../../../components/DropCollapse/DropCollapse';
import TimeSelect from './components/timeSelect/TimeSelect';
import { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import orgService from '../../../services/orgService';
import { useLocation, useParams } from 'react-router-dom';
import PlUpload from '../../../components/PlUpload/PlUpload';
import paymethods from './components/paymethods';
import weektimes from './components/weektimes';
import weekreservtime from './components/weekreservtime';
import timeTransform from './components/timeTransform';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PayMethods from '../../../components/PayMethods/PayMethods';
import SaveIcon from '../../../icons/SaveIcon/SaveIcon';
import PolygonModal from '../modals/PolygonModal/PolygonModal';
import LocationModal from '../modals/LocationModal/LocationModa';
import MapMarker from '../../../components/MapMarker/MapMarker';
import checkNumValue from '../../../funcs/checkNumValue';
import UploadKml from './components/UploadKml/UploadKml';
import MapPolygonPic from '../../../components/MapPolygonPic/MapPolygonPic';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
// import paymentTypes from '../../../funcs/paymentTypes';
import switchCrm from '../../../funcs/switchCrm';
import checkNull from '../../../funcs/checkNull';
import PaymentEdit from './modals/PaymentEdit/PaymentEdit';
import checkDomain from '../../../funcs/checkDomain';
import { checkIsBao } from "../../../utils/checkIsBao";
import { checkIsTigrus } from "../../../utils/checkIsTigrus";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import checkDom from '../../../funcs/checkDom';
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import BasketOnlinePay from '../../basket/components/BasketOnlinePay/BasketOnlinePay';

Quill.register('modules/htmlEditButton', htmlEditButton);


const os = new orgService();
const pmValueFind = (value) => {
    switch (value?.toString() ?? '') {
        case '0':
            return 'Оплата наличными'
        case '2':
            return 'Оплата по карте в приложении'
        case '1':
            return 'Оплата по карте при получении'
        case '3':
            return 'Оплата бонусами'
        default:
            return 'Оплата наличными'
    }
}


dayjs.extend(customParseFormat);

const disabledDate = (current) => {
  // Can not select days before today
  return current < dayjs().endOf('day');
};




const LOCAL_STORAGE = window.localStorage;


const OrgsCreatePage = () => {
    const { token, settings } = useSelector(state => state)
    const { brandId, orgId } = useParams();
    const [createdId, setCreatedId] = useState('')
    const nav = useNavigate()
    const [editPolygon, setEditPolygon] = useState()


    //GLOBAL VALUES
    const [coords, setCoords] = useState(checkDomain({ lat: 55.7522200, lng: 37.6155600 }, { lat: 43.23365, lng: 76.89623 }))
    const [ThumbnailPrev, setThumbnailPrev] = useState(null)
    const [weekTimes, setWeekTimes] = useState(weektimes)
    const [weekReservationTimes, setReservationWeekTimes] = useState(weekreservtime)


    //VALUES
    const [IIkoID, setIIkoID] = useState('')
    const [IIkoIDTerminal, setIIkoIDTerminal] = useState('')
    const [OrganisationBrand, setOrganisationBrand] = useState('')
    const [ItemOrder, setItemOrder] = useState(0)


    const [Name, setName] = useState('')
    const [NameEn, setNameEn] = useState('')
    const [NameKz, setNameKz] = useState('')


    const nameTabs = [
        {
            key: '1',
            label: 'Русский язык',
            children: <Input
                maskType={String}
                value={Name?.toString() ?? ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={'Название организации'} />
        },
        {
            key: '2',
            label: 'Казахский язык',
            children: <Input
                maskType={String}
                value={NameKz?.toString() ?? ''}
                onChange={(e) => setNameKz(e.target.value)}
                placeholder={'Название организации'} />
        },
        {
            key: '3',
            label: 'Английский язык',
            children: <Input
                maskType={String}
                value={NameEn?.toString() ?? ''}
                onChange={(e) => setNameEn(e.target.value)}
                placeholder={'Название организации'} />
        },
    ];

    const [Description, setDescription] = useState('')
    const [Props, setProps] = useState('')
    const [ThumbnailPicture, setThumbnailPicture] = useState(null)
    const [tag, setTag] = useState('')
    const [Address, setAddress] = useState('')
    const [Phone, setPhone] = useState('')
    const [MinPriceForLocalSale, setMinPriceForLocalSale] = useState('')
    const [LocalOrderSale, setLocalOrderSale] = useState('')
    const [IsHaveDelivery, setIsHaveDelivery] = useState('0')
    const [IsHaveLocalOrder, setIsHaveLocalOrder] = useState('0')
    const [TimetableDescription, setTimetableDescription] = useState('')
    const [Lattitude, setLattitude] = useState('0')
    const [Longitude, setLongitude] = useState('0')
    const [BotToken, setBotToken] = useState('')
    const [Email, setEmail] = useState('')
    const [IsNeedToNotify, setIsNeedToNotify] = useState('0')
    const [NotifyWhenNewOrder, setNotifyWhenNewOrder] = useState('0')
    const [NotifyWhenIIkoErrors, setNotifyWhenIIkoErrors] = useState('0')
    const [NotifyWhenOrderChanges, setNotifyWhenOrderChanges] = useState('0')
    const [Timezone, setTimezone] = useState('')
    const [CountTimeStepsPreorder, setCountTimeStepsPreorder] = useState('');
    const [TimeStep, setTimeStep] = useState('');
    const [Disabled, setDisabled] = useState('0')
    const [HavePreorder, setHavePreorder] = useState('0')
    const [CountTimeStepsReservation, setCountTimeStepsReservation] = useState('')
    const [TimeStepReservation, setTimeStepReservation] = useState('')
    const [HaveReservation, setHaveReservation] = useState('0')
    const [NotifyWhenNewReservation, setNotifyWhenNewReservation] = useState('0')
    const [HideInApp, setHideInApp] = useState('0')
    const [PaymentSystemType, setPaymentSystemType] = useState('Русский стандарт')

    const [RKeeperLogin, setRKeeperLogin] = useState('')
    const [RKeeperIP, setRKeeperIP] = useState('')
    const [RKeeperPort, setRKeeperPort] = useState('')
    const [PrimehillToken, setPrimehillToken] = useState('')
    const [CanOverwrite, setCanOverwrite] = useState('0')
    const [BotChatID, setBotChatID] = useState('')
    const [TimeForSelfPickup, setTimeForSelfPickup] = useState('');
    const [transfer_to_call_center, settransfer_to_call_center] = useState('0')
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [timezonesList, setTimezonesList] = useState([]);

    const [polList, setPolList] = useState([])

    //MODALS
    const [selectLocationModal, setSelectLocationModal] = useState(false);
    const [selectPolyModal, setSelectPolyModal] = useState(false)
    const [isEventsShowModalOpen, setIsEventsShowModalOpen] = useState(false);
    const [isEditEventShowModalOpen, setIsEditEventShowModalOpen] = useState(false);
    const [isAddEventShowModalOpen, setIsAddEventShowModalOpen] = useState(false);
    const [isEventsMasterModalOpen, setIsEventsMasterModalOpen] = useState(false);
    const [isEditEventMasterModalOpen, setIsEditEventMasterModalOpen] = useState(false);
    const [isAddEventMasterModalOpen, setIsAddEventMasterModalOpen] = useState(false);
    const [eventId, setEventId] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventName, setEventName] = useState('');
    const [eventType, setEventType] = useState('');
    const [eventLocalDate, setEventLocalDate] = useState('');
    const [eventLocalName, setEventLocalName] = useState('');
    const [eventLocalType, setEventLocalType] = useState('');
    const [masterModalData, setMasterModalData] = useState([]);
    const [showModalData, setShowModalData] = useState([]);
    const [saveLoad, setSaveLoad] = useState(false)
    const [delLoad, setDelLoad] = useState(false)

    // Bonuses
    const [MaxBonusesPercent, setMaxBonusesPercent] = useState('')
    const [MaxBonusesPayment, setMaxBonusesPayment] = useState('')
    const [bonusDel, setBonusDel] = useState('0')
    const [bonusLocal, setBonusLocal] = useState('0')
    const [OrderBonusesGetMax, setOrderBonusesGetMax] = useState('')
    const [OrderBonusesGetPercent, setOrderBonusesGetPercent] = useState('')
    const [payBonusDel, setPayBonusDel] = useState('0')
    const [payBonusLocal, setPayBonusLocal] = useState('0')
    const [OnlyPayOnlineWhen, setOnlyPayOnlineWhen] = useState('0')

    // show values
    const showEventsShowModal = () => {
        setIsEventsShowModalOpen(true);
      };
      const handleEventsShowModalCancel = () => {
        setIsEventsShowModalOpen(false);
      };
    const showEditEventShowModal = (id, text, date) => {
        setEventId(id);
        setEventName(text);
        setEventDate(date);
        setIsEditEventShowModalOpen(true);
      };
      const handleEditEventShowModalCancel = () => {
        setIsEditEventShowModalOpen(false);
      };
    const showAddEventShowModal = () => {
        setIsAddEventShowModalOpen(true);
      };
      const handleAddEventShowModalCancel = () => {
        setIsAddEventShowModalOpen(false);
      };
    //   master values
    const showEventsMasterModal = () => {
        setIsEventsMasterModalOpen(true);
      };
      const handleEventsMasterModalCancel = () => {
        setIsEventsMasterModalOpen(false);
      };
    const showEditEventMasterModal = (id, text, date) => {
        setEventId(id);
        setEventName(text);
        setEventDate(date);
        setIsEditEventMasterModalOpen(true);
      };
      const handleEditEventMasterModalCancel = () => {
        setIsEditEventMasterModalOpen(false);
      };
    const showAddEventMasterModal = () => {
        setIsAddEventMasterModalOpen(true);
      };
      const handleAddEventMasterModalCancel = () => {
        setIsAddEventMasterModalOpen(false);
      };

    //Способы оплаты
    const [pm, setPm] = useState([]);
    const [delivery, setDelivery] = useState(false)

    const [payEditModal, setPayEditModal] = useState(false);
    const [selectedPay, setSelectedPay] = useState(null)

    const getMasterEvents = async () => {
        try {
            const d = new FormData();
            d.append('type', 0);
            d.append('organisationsId', orgId)
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/getOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: d,
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setMasterModalData(responseData);
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      };
      const addMasterEvent = async () => {
        try {
            const d = new FormData();
            d.append('organisationsId', orgId);
            d.append('text', eventLocalName);
            d.append('type', 0);
            d.append('date', eventLocalDate);
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/setOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify({ organisationsId: orgId, text: eventLocalName, type: eventLocalType, date: eventLocalDate }),
                body: d
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setEventLocalDate('');
            setEventLocalName('');
            getMasterEvents();
            // handleEventsMasterModalCancel();
            notification.success({
                message: 'Мастер-класс успешно добавлен',
                placement: 'top'
            });
            handleAddEventMasterModalCancel();
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      }
      const editMasterEvent = async () => {
        try {
            const d = new FormData();
            d.append('id', eventId)
            d.append('organisationsId', orgId);
            d.append('text', eventName);
            d.append('type', 0);
            d.append('date', eventDate);
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/setOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify({ organisationsId: orgId, text: eventLocalName, type: eventLocalType, date: eventLocalDate }),
                body: d
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setEventLocalDate('');
            setEventLocalName('');
            getMasterEvents();
            // handleEventsMasterModalCancel();
            notification.success({
                message: 'Мастер-класс успешно отредактирован',
                placement: 'top'
            });
            handleEditEventMasterModalCancel();
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      }
    const getShowEvents = async () => {
        try {
            const d = new FormData();
            d.append('type', 1);
            d.append('organisationsId', orgId)
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/getOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: d,
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setShowModalData(responseData);
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      };
      const addShowEvent = async () => {
        try {
            const d = new FormData();
            d.append('organisationsId', orgId);
            d.append('text', eventLocalName);
            d.append('type', 1);
            d.append('date', eventLocalDate);
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/setOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify({ organisationsId: orgId, text: eventLocalName, type: eventLocalType, date: eventLocalDate }),
                body: d
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setEventLocalDate('');
            setEventLocalName('');
            getShowEvents();
            // handleEventsMasterModalCancel();
            notification.success({
                message: 'Шоу-программа успешно добавлена',
                placement: 'top'
            });
            handleAddEventShowModalCancel();
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      }
      const editShowEvent = async () => {
        try {
            const d = new FormData();
            d.append('id', eventId)
            d.append('organisationsId', orgId);
            d.append('text', eventName);
            d.append('type', 1);
            d.append('date', eventDate);
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/setOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify({ organisationsId: orgId, text: eventLocalName, type: eventLocalType, date: eventLocalDate }),
                body: d
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            setEventLocalDate('');
            setEventLocalName('');
            getShowEvents();
            // handleEventsMasterModalCancel();
            notification.success({
                message: 'Шоу-программа успешно отредактирована',
                placement: 'top'
            });
            handleEditEventShowModalCancel();
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      }

      const deleteEvent = async (id) => {
        try {
            const d = new FormData();
            d.append('id', id)
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/NewAdminPanel/organisation/deleteOrganisationsEvent`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify({ organisationsId: orgId, text: eventLocalName, type: eventLocalType, date: eventLocalDate }),
                body: d
              }
            );
      
            if (!response.ok) {
              throw new Error("Ошибка HTTP: " + response.status);
            }
      
            const responseData = await response.json();
            getShowEvents();
            getMasterEvents();
            handleEditEventMasterModalCancel();
            handleEditEventShowModalCancel();
            notification.success({
                message: 'Мероприятие успешно удалено',
                placement: 'top'
            });
            // handleEditEventShowModalCancel();
            console.log(responseData);
          } catch (error) {
            console.error("Ошибка при получении страницы:", error);
          }
      }


    //получение данных при редактировании
    useEffect(() => {
        getMasterEvents();
        getShowEvents();
        if (orgId && brandId != 'nobrand' && token && settings.IsHaveBrands == '1') {
            os.getOrgs(token, { BrandID: brandId }).then(res => {

                const thisOrg = res.find(item => item.ID == orgId)


                if (thisOrg?.ThumbnailPicture || thisOrg?.Name) {
                    LOCAL_STORAGE.setItem('gs-creating-org', '1')
                } else {
                    LOCAL_STORAGE.removeItem('gs-creating-org')
                }
                setPaymentSystemType(thisOrg?.PaymentSystemType != '' ? thisOrg?.PaymentSystemType : 'Русский стандарт')
                setIIkoID(thisOrg?.IIkoID)
                setIIkoIDTerminal(thisOrg?.IIkoIDTerminal)
                setOrganisationBrand(thisOrg?.OrganisationBrand)
                setItemOrder(thisOrg?.ItemOrder)

                setName(thisOrg?.Name)
                setNameKz(thisOrg?.Name_kz)
                setNameEn(thisOrg?.Name_en)


                setDescription(checkNull(thisOrg?.Description))
                setProps(thisOrg?.requisites)
                setThumbnailPrev(thisOrg?.ThumbnailPicture)
                setAddress(checkNull(thisOrg?.Address))
                setTag(checkNull(thisOrg?.tag))
                setPhone(checkNull(thisOrg?.Phone))
                setMinPriceForLocalSale(thisOrg?.MinPriceForLocalSale != '0' ? thisOrg?.MinPriceForLocalSale : '')
                setLocalOrderSale(thisOrg?.LocalOrderSale != '0' ? thisOrg.LocalOrderSale : '')
                setIsHaveDelivery(checkNull(thisOrg?.IsHaveDelivery, true))
                setIsHaveLocalOrder(checkNull(thisOrg?.IsHaveLocalOrder, true))
                setTimetableDescription(checkNull(thisOrg?.TimetableDescription))
                setLattitude(thisOrg?.Lattitude)
                setLongitude(thisOrg?.Longitude)
                setCanOverwrite(checkNull(thisOrg?.CanOverwrite, true))
                setHideInApp(checkNull(thisOrg?.HideInApp, true))
                if (thisOrg.Lattitude && thisOrg.Longitude) {
                    setCoords({ lat: Number(thisOrg.Lattitude), lng: Number(thisOrg.Longitude) })
                } else {
                    setCoords(checkDomain({ lat: 55.7522200, lng: 37.6155600 }, { lat: 43.23365, lng: 76.89623 }))
                }
                setBotToken(checkNull(thisOrg?.BotToken))
                setEmail(checkNull(thisOrg?.Email))
                setIsNeedToNotify(checkNull(thisOrg?.IsNeedToNotify, true))
                setNotifyWhenNewOrder(checkNull(thisOrg?.NotifyWhenNewOrder, true))
                setNotifyWhenIIkoErrors(checkNull(thisOrg?.NotifyWhenIIkoErrors, true))
                setNotifyWhenOrderChanges(checkNull(thisOrg?.NotifyWhenOrderChanges, true))
                setTimezone(checkNull(thisOrg?.Timezone))
                setCountTimeStepsPreorder(thisOrg?.CountTimeStepsPreorder != '0' ? thisOrg.CountTimeStepsPreorder : '')
                setDisabled(checkNull(thisOrg?.Disabled, true))
                setTimeStep(checkNull(thisOrg?.TimeStep))
                setHavePreorder(checkNull(thisOrg?.HavePreorder, true))
                setCountTimeStepsReservation(thisOrg?.CountTimeStepsReservation != '0' ? thisOrg.CountTimeStepsReservation : '')
                setTimeStepReservation(thisOrg?.TimeStepReservation != '0' ? thisOrg.TimeStepReservation : '')
                setHaveReservation(checkNull(thisOrg?.HaveReservation, true))
                setNotifyWhenNewReservation(checkNull(thisOrg?.NotifyWhenNewReservation, true))
                setBotChatID(checkNull(thisOrg?.BotChatID))
                setTimeForSelfPickup(checkNull(thisOrg?.TimeForSelfPickup));
                setWeekTimes([
                    timeTransform(thisOrg?.MonTime == 'Closed' ? thisOrg?.MonTime : thisOrg?.MonTime?.split(','), 0),
                    timeTransform(thisOrg?.TueTime == 'Closed' ? thisOrg?.TueTime : thisOrg?.TueTime?.split(','), 1),
                    timeTransform(thisOrg?.WedTime == 'Closed' ? thisOrg?.WedTime : thisOrg?.WedTime?.split(','), 2),
                    timeTransform(thisOrg?.ThuTime == 'Closed' ? thisOrg?.ThuTime : thisOrg?.ThuTime?.split(','), 3),
                    timeTransform(thisOrg?.FriTime == 'Closed' ? thisOrg?.FriTime : thisOrg?.FriTime?.split(','), 4),
                    timeTransform(thisOrg?.SatTime == 'Closed' ? thisOrg?.SatTime : thisOrg?.SatTime?.split(','), 5),
                    timeTransform(thisOrg?.SunTime == 'Closed' ? thisOrg?.SunTime : thisOrg?.SunTime?.split(','), 6),
                ]);
                setReservationWeekTimes([
                    timeTransform(thisOrg?.MonTime_reservation == 'Closed' ? thisOrg?.MonTime_reservation : thisOrg?.MonTime_reservation?.split(','), 0), 
                    timeTransform(thisOrg?.TueTime_reservation == 'Closed' ? thisOrg?.TueTime_reservation : thisOrg?.TueTime_reservation?.split(','), 1), 
                    timeTransform(thisOrg?.WedTime_reservation == 'Closed' ? thisOrg?.WedTime_reservation : thisOrg?.WedTime_reservation?.split(','), 2),
                    timeTransform(thisOrg?.ThuTime_reservation == 'Closed' ? thisOrg?.ThuTime_reservation : thisOrg?.ThuTime_reservation?.split(','), 3),
                    timeTransform(thisOrg?.FriTime_reservation == 'Closed' ? thisOrg?.FriTime_reservation : thisOrg?.FriTime_reservation?.split(','), 4),
                    timeTransform(thisOrg?.SatTime_reservation == 'Closed' ? thisOrg?.SatTime_reservation : thisOrg?.SatTime_reservation?.split(','), 5),
                    timeTransform(thisOrg?.SunTime_reservation == 'Closed' ? thisOrg?.SunTime_reservation : thisOrg?.SunTime_reservation?.split(','), 6),
                ]);
                setRKeeperLogin(checkNull(thisOrg?.RKeeperLogin))
                setRKeeperIP(checkNull(thisOrg?.RKeeperIP))
                setRKeeperPort(checkNull(thisOrg?.RKeeperPort))
                setPrimehillToken(checkNull(thisOrg?.PrimehillToken))
                settransfer_to_call_center(thisOrg?.transfer_to_call_center)

            })
            os.getPols(token, { OrganisationID: orgId }).then(res => {
                if (res?.length > 0) {
                    setDelivery(true)
                    setPolList(res.filter(item => item.Disabled == '0').map(item => {
                        return {
                            ...item,
                            Coordinates: item.Coordinats.split(' ').map(item => {
                                return {
                                    lat: Number(item.slice(0, item.indexOf(','))),
                                    lng: Number(item.slice(item.indexOf(',') + 1, item.length))
                                }
                            }),
                            Coordinats: null,
                        }
                    }))
                } else {
                    setDelivery(false)
                }
            })
            os.getPay(token, { OrganisationID: orgId }).then(res => {
                setPm(res.filter(item => item.Disabled == '0').map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
            })
        }
        if (orgId && brandId == 'nobrand' && token && settings?.IsHaveBrands == '0') {
            os.getOrgs(token).then(res => {
                const thisOrg = res.find(item => item.ID == orgId)
                if (thisOrg?.ThumbnailPicture || thisOrg?.Name) {
                    LOCAL_STORAGE.setItem('gs-creating-org', '1')
                } else {
                    LOCAL_STORAGE.removeItem('gs-creating-org')
                }
                setPaymentSystemType(thisOrg?.PaymentSystemType != '' ? thisOrg?.PaymentSystemType : 'Русский стандарт')
                setIIkoID(thisOrg?.IIkoID)
                setIIkoIDTerminal(thisOrg?.IIkoIDTerminal)
                setOrganisationBrand(thisOrg?.OrganisationBrand)
                setItemOrder(thisOrg?.ItemOrder)
                setName(thisOrg?.Name)
                setNameKz(thisOrg?.Name_kz)
                setNameEn(thisOrg?.Name_en)
                setDescription(checkNull(thisOrg?.Description))
                setProps(thisOrg?.requisites)
                setThumbnailPrev(thisOrg?.ThumbnailPicture)
                setAddress(checkNull(thisOrg?.Address))
                setTag(checkNull(thisOrg?.tag))

                setPhone(checkNull(thisOrg?.Phone))
                setMinPriceForLocalSale(thisOrg?.MinPriceForLocalSale != '0' ? thisOrg?.MinPriceForLocalSale : '')
                setLocalOrderSale(thisOrg?.LocalOrderSale != '0' ? thisOrg.LocalOrderSale : '')
                setIsHaveDelivery(checkNull(thisOrg?.IsHaveDelivery, true))
                setIsHaveLocalOrder(checkNull(thisOrg?.IsHaveLocalOrder, true))
                setTimetableDescription(checkNull(thisOrg?.TimetableDescription))
                setLattitude(thisOrg?.Lattitude)
                setLongitude(thisOrg?.Longitude)
                setCanOverwrite(checkNull(thisOrg?.CanOverwrite, true))
                if (thisOrg.Lattitude && thisOrg.Longitude) {
                    setCoords({ lat: Number(thisOrg.Lattitude), lng: Number(thisOrg.Longitude) })
                } else {
                    setCoords(checkDomain({ lat: 55.7522200, lng: 37.6155600 }, { lat: 43.23365, lng: 76.89623 }))
                }
                setBotChatID(checkNull(thisOrg?.BotChatID))
                setBotToken(checkNull(thisOrg?.BotToken))
                setEmail(checkNull(thisOrg?.Email))
                setIsNeedToNotify(checkNull(thisOrg?.IsNeedToNotify, true))
                setNotifyWhenNewOrder(checkNull(thisOrg?.NotifyWhenNewOrder, true))
                setNotifyWhenIIkoErrors(checkNull(thisOrg?.NotifyWhenIIkoErrors, true))
                setNotifyWhenOrderChanges(checkNull(thisOrg?.NotifyWhenOrderChanges, true))
                setTimezone(thisOrg?.Timezone)
                setCountTimeStepsPreorder(thisOrg?.CountTimeStepsPreorder != '0' ? thisOrg.CountTimeStepsPreorder : '')
                setDisabled(checkNull(thisOrg?.Disabled, true))
                setTimeStep(checkNull(thisOrg?.TimeStep))
                setHavePreorder(checkNull(thisOrg?.HavePreorder, true))
                setCountTimeStepsReservation(thisOrg?.CountTimeStepsReservation != '0' ? thisOrg.CountTimeStepsReservation : '')
                setTimeStepReservation(thisOrg?.TimeStepReservation != '0' ? thisOrg.TimeStepReservation : '')
                setHaveReservation(checkNull(thisOrg?.HaveReservation, true))
                setNotifyWhenNewReservation(checkNull(thisOrg?.NotifyWhenNewReservation, true))
                setWeekTimes([
                    timeTransform(thisOrg?.MonTime == 'Closed' ? thisOrg?.MonTime : thisOrg?.MonTime?.split(','), 0),
                    timeTransform(thisOrg?.TueTime == 'Closed' ? thisOrg?.TueTime : thisOrg?.TueTime?.split(','), 1),
                    timeTransform(thisOrg?.WedTime == 'Closed' ? thisOrg?.WedTime : thisOrg?.WedTime?.split(','), 2),
                    timeTransform(thisOrg?.ThuTime == 'Closed' ? thisOrg?.ThuTime : thisOrg?.ThuTime?.split(','), 3),
                    timeTransform(thisOrg?.FriTime == 'Closed' ? thisOrg?.FriTime : thisOrg?.FriTime?.split(','), 4),
                    timeTransform(thisOrg?.SatTime == 'Closed' ? thisOrg?.SatTime : thisOrg?.SatTime?.split(','), 5),
                    timeTransform(thisOrg?.SunTime == 'Closed' ? thisOrg?.SunTime : thisOrg?.SunTime?.split(','), 6),
                ]);
                setReservationWeekTimes([
                    timeTransform(thisOrg?.MonTime_reservation == 'Closed' ? thisOrg?.MonTime_reservation : thisOrg?.MonTime_reservation?.split(','), 0), 
                    timeTransform(thisOrg?.TueTime_reservation == 'Closed' ? thisOrg?.TueTime_reservation : thisOrg?.TueTime_reservation?.split(','), 1), 
                    timeTransform(thisOrg?.WedTime_reservation == 'Closed' ? thisOrg?.WedTime_reservation : thisOrg?.WedTime_reservation?.split(','), 2),
                    timeTransform(thisOrg?.ThuTime_reservation == 'Closed' ? thisOrg?.ThuTime_reservation : thisOrg?.ThuTime_reservation?.split(','), 3),
                    timeTransform(thisOrg?.FriTime_reservation == 'Closed' ? thisOrg?.FriTime_reservation : thisOrg?.FriTime_reservation?.split(','), 4),
                    timeTransform(thisOrg?.SatTime_reservation == 'Closed' ? thisOrg?.SatTime_reservation : thisOrg?.SatTime_reservation?.split(','), 5),
                    timeTransform(thisOrg?.SunTime_reservation == 'Closed' ? thisOrg?.SunTime_reservation : thisOrg?.SunTime_reservation?.split(','), 6),
                ]);
                setRKeeperLogin(checkNull(thisOrg?.RKeeperLogin))
                setRKeeperIP(checkNull(thisOrg?.RKeeperIP))
                setRKeeperPort(checkNull(thisOrg?.RKeeperPort))
                setPrimehillToken(checkNull(thisOrg?.PrimehillToken))
                setHideInApp(checkNull(thisOrg?.HideInApp, true))
                setTimeForSelfPickup(checkNull(thisOrg?.TimeForSelfPickup))
                settransfer_to_call_center(thisOrg?.transfer_to_call_center)

            })
            os.getPols(token, { OrganisationID: orgId }).then(res => {
                if (res?.length > 0) {
                    setDelivery(true)
                    setPolList(res.filter(item => item.Disabled == '0').map(item => {
                        return {
                            ...item,
                            Coordinates: item.Coordinats.split(' ').map(item => {
                                return {
                                    lat: Number(item.slice(0, item.indexOf(','))),
                                    lng: Number(item.slice(item.indexOf(',') + 1, item.length))
                                }
                            }),
                            Coordinats: null,
                        }
                    }))
                } else {
                    setDelivery(false)
                }
            })
            os.getPay(token, { OrganisationID: orgId }).then(res => {
                setPm(res.filter(item => item.Disabled == '0').map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
            })
        }
        os.getPaymentsType(token).then(res => {
            setPaymentTypes(res.map(payment => ({
                value: payment.payment_type
            })))
            console.log(res.map(payment => ({
                value: payment.payment_type
            })))
        })
        os.getTimezones(token).then(res => {
            console.log(res)
            setTimezonesList(res.map(payment => ({
                value: payment.timezone
            })))
        })
    }, [orgId, brandId, token, settings, orgId])


    const updatePolList = () => {
        os.getPols(token, { OrganisationID: orgId }).then(res => {

            if (res?.length > 0) {
                setDelivery(true)
                setPolList(res.filter(item => item.Disabled == '0').map(item => {
                    return {
                        ...item,
                        Coordinates: item.Coordinats.split(' ').map(item => {
                            return {
                                lat: Number(item.slice(0, item.indexOf(','))),
                                lng: Number(item.slice(item.indexOf(',') + 1, item.length))
                            }
                        }),
                        Coordinats: null,
                    }
                }))
            } else {
                setDelivery(false)
            }
        })
    }

    const addPayMethods = () => {
        const cs = pm;
        let csN = paymethods.map(i => Number(i.PaymentType))
        let pmN = cs.map(i => Number(i.PaymentType))
        let dif = csN.filter(n => pmN.indexOf(n) === -1);

        if (dif.length > 0) {
            const addItem = paymethods.find(i => Number(i.PaymentType) == dif[0])
            os.addPay(token, {
                OrganisationID: createdId ? createdId : orgId,
                Payments: [
                    {
                        PaymentType: addItem.PaymentType,
                        IsNeedToChangeCash: addItem.IsNeedToChangeCash ? '1' : '0'
                    }
                ],
            }).then(res => {
                setPm(res.map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
            })
        }
    }

    const handleSelectPayment = (e) => {
        const cs = pm;
        let csN = paymethods.map(i => Number(i.PaymentType))
        let pmN = cs.map(i => Number(i.PaymentType))
        let dif = csN.filter(n => pmN.indexOf(n) === -1);

        if (dif.length > 0) {
            const addItem = paymethods.find(i => Number(i.PaymentType) == dif[0])
            os.addPay(token, {
                OrganisationID: createdId ? createdId : orgId,
                Payments: [
                    {
                        PaymentType: addItem.PaymentType,
                        IsNeedToChangeCash: addItem.IsNeedToChangeCash ? '1' : '0'
                    }
                ],
            }).then(res => {
                setPm(res.map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
            })
        }
    }

    const deletePayMethod = (index, id) => {
        os.deletePay(token, {
            ID: id
        }).then(res => {
            setPm(res.map(item => {
                return {
                    ...item,
                    value: pmValueFind(item.PaymentType)
                }
            }))
        })
    }

    //выбор таймзоны
    const selectTmz = (value, index) => {
        setTimezone(value);
    }

    //открыть модалку местоположения
    const openSelectLocation = () => {
        setSelectLocationModal(true)
    }

    //закрыть модалку местоположения
    const closeSelectLocation = () => {
        setSelectLocationModal(false)
    }

    //открыть модалку создания полигона
    const openSelectPoly = () => {
        setSelectPolyModal(true)
    }

    //закрыть модалку создания полигона
    const closeSelectPoly = () => {
        setEditPolygon(null)
        setSelectPolyModal(false)
    }

    //сохранить время
    const saveTime = (index, value) => {
        let ur = weekTimes;
        let rm = ur.splice(index, 1, value)
        setWeekTimes([...ur]);
    }
    const saveReservationTime = (index, value) => {
        let ur = weekReservationTimes;
        let rm = ur.splice(index, 1, value)
        setReservationWeekTimes([...ur]);
    }

    //выбрать местоположение
    const setLocation = (coords) => {
        setLattitude(coords.lat)
        setLongitude(coords.lng)
        setCoords({ lat: coords.lat, lng: coords.lng })
    }


    //добавить изображение
    const uploadImage = (e) => {
        setThumbnailPrev(URL.createObjectURL(e.target.files[0]))
        setThumbnailPicture(e.target.files[0])
    }

    //сохранение изменений
    const orgSubmit = () => {
        let weekArray = []
        let reservationWeekArray = []
        if (weekTimes.length > 0) {
            weekArray = weekTimes.map(item => {
                if (!item.rest) {
                    return (
                        item.values?.map((i, ind) => {
                            return (
                                `${60 * Number(i.start.substring(0, 2)) + Number(i.start.substring(3, 5))}-${(60 * Number(i.end.substring(0, 2))) + Number(i.end.substring(3, 5))}`
                            )
                        }).join(',')
                    )
                }
                return 'Closed'
            })
        }
        if(weekReservationTimes.length > 0) {
            reservationWeekArray = weekReservationTimes.map(item => {
                if(!item.rest) {
                    return (
                        item.values?.map((i, ind) => {
                            return (
                                `${60 * Number(i.start.substring(0,2)) + Number(i.start.substring(3,5))}-${(60 * Number(i.end.substring(0,2))) + Number(i.end.substring(3,5))}`
                            )
                        }).join(',')
                    )
                }
                return 'Closed'
            }) 
        }
        const data = new FormData()
        data.append('PaymentSystemType', PaymentSystemType)
        data.append('IIkoID', IIkoID)
        data.append('IIkoIDTerminal', IIkoIDTerminal)
        data.append('OrganisationBrand', brandId != 'nobrand' && brandId ? brandId : 0)
        data.append('ItemOrder', ItemOrder)
        data.append('Name', Name)
        data.append('Name_en', NameEn)
        data.append('Name_kz', NameKz)

        data.append('Description', Description)
        data.append('requisites', Props);
        if (ThumbnailPicture) {
            data.append('ThumbnailPicture', ThumbnailPicture)
        }
        checkNumValue(data, 'HaveReservation', HaveReservation)
        checkNumValue(data, 'CountTimeStepsReservation', CountTimeStepsReservation)
        checkNumValue(data, 'TimeStepReservation', TimeStepReservation)
        data.append('HavePreorder', HavePreorder)
        data.append('Address', Address)
        if (checkIsTigrus()) {
            data.append('tag', tag)
        }
        data.append('Phone', Phone)
        data.append('Email', Email)
        checkNumValue(data, 'MinPriceForLocalSale', MinPriceForLocalSale);
        checkNumValue(data, 'LocalOrderSale', LocalOrderSale)
        data.append('Lattitude', coords?.lat)
        data.append('Longitude', coords?.lng)
        data.append('IsHaveDelivery', IsHaveDelivery)
        data.append('IsHaveLocalOrder', IsHaveLocalOrder)
        data.append('TimetableDescription', TimetableDescription)
        data.append('MonTime', weekArray[0])
        data.append('TueTime', weekArray[1])
        data.append('WedTime', weekArray[2])
        data.append('ThuTime', weekArray[3])
        data.append('FriTime', weekArray[4])
        data.append('SatTime', weekArray[5])
        data.append('SunTime', weekArray[6])
        data.append('MonTime_reservation', reservationWeekArray[0])
        data.append('TueTime_reservation', reservationWeekArray[1])
        data.append('WedTime_reservation', reservationWeekArray[2])
        data.append('ThuTime_reservation', reservationWeekArray[3])
        data.append('FriTime_reservation', reservationWeekArray[4])
        data.append('SatTime_reservation', reservationWeekArray[5])
        data.append('SunTime_reservation', reservationWeekArray[6])
        data.append('BotChatID', BotChatID)
        data.append('Timezone', Timezone)
        checkNumValue(data, 'CountTimeStepsPreorder', CountTimeStepsPreorder)
        checkNumValue(data, 'TimeStep', TimeStep)
        data.append('Disabled', Disabled)
        data.append('IsNeedToNotify', IsNeedToNotify)
        data.append('BotToken', BotToken)
        data.append('NotifyWhenIIkoErrors', NotifyWhenIIkoErrors)
        data.append('NotifyWhenNewOrder', NotifyWhenNewOrder)
        data.append('NotifyWhenOrderChanges', NotifyWhenOrderChanges)
        data.append('NotifyWhenNewReservation', NotifyWhenNewReservation);
        data.append('HideInApp', HideInApp);
        data.append('RKeeperLogin', RKeeperLogin)
        data.append('RKeeperIP', RKeeperIP)
        data.append('RKeeperPort', RKeeperPort)
        data.append('PrimehillToken', PrimehillToken)
        data.append('CanOverwrite', CanOverwrite)
        data.append('TimeForSelfPickup', TimeForSelfPickup);
        data.append('transfer_to_call_center', transfer_to_call_center)
        // for(var pair of data.entries()) {
        //     console.log(pair[0]+ ': '+ pair[1]);
        // }  
        setSaveLoad(true)
        if (!orgId) {
            os.addOrg(token, data).then(res => {
                if (res?.error) {
                    message.error(res.message)
                } else {
                    message.success('Организация создана')
                    setCreatedId(res)
                }
            }).finally(_ => {
                setSaveLoad(false)
            })
        }
        if (orgId) {
            data.append('ID', orgId)
            os.editOrg(token, data).then(res => {
                if (res?.error) {
                    message.error(res.message)
                } else {
                    nav(-1, { replace: true })
                    message.success('Организация успешно изменена')
                }
            }).finally(_ => {
                setSaveLoad(false)
            })
        }
    }


    //удаление
    const deleteOrg = () => {
        setDelLoad(true)
        os.deleteOrg(token, { ID: orgId }).then(res => {

            if (res?.error) {
                message.error(res.message)
            } else {
                nav(-1, { replace: true })
                message.success('Организация удалена')
            }
        }).finally(_ => {
            setDelLoad(false)
        })
    }

    //изменение полигона
    const editPolygonFunc = ({ ...item }) => {
        setEditPolygon(item)
        openSelectPoly()
    }


    const addPay = (item, selected) => {

        if (item.PaymentType != selected.PaymentType && !pm.find(i => i.PaymentType == item.PaymentType)) {
            os.deletePay(token, { ID: selected.ID }).then(res => {
                if (res) {
                    os.addPay(token, {
                        OrganisationID: createdId ? createdId : orgId,
                        Payments: [
                            {
                                PaymentType: item.PaymentType,
                                IsNeedToChangeCash: item.IsNeedToChangeCash
                            }
                        ]
                    }).then(r => {

                        if (r) {
                            message.success('Метод оплаты успешно добавлен')
                        }
                        setPm(r.map(item => {
                            return {
                                ...item,
                                value: pmValueFind(item.PaymentType)
                            }
                        }))
                    })
                }
            })

        } else {
            message.info('Данный метод оплаты уже выбран')
        }
    }


    const deletePay = (ID) => {
        os.deletePay(token, { ID }).then(res => {
            if (res) {
                setPm(res.map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
                message.success('Метод оплаты удален')
            }

        })
    }


    const editPay = (item) => {
        console.log(item)
        os.editPay(token, item).then(res => {
            if (res) {
                console.log(res)
                setPm(res.map(item => {
                    return {
                        ...item,
                        value: pmValueFind(item.PaymentType)
                    }
                }))
            }
        })
    }


    const selectPaySystem = (item) => {
        setPaymentSystemType(item)
    }
    const selectTimezone = (item) => {
        setTimezone(item)
    }

    useEffect(() => {
        if (!ThumbnailPrev || !Name) {
            LOCAL_STORAGE.removeItem('gs-creating-org')
        } else {
            LOCAL_STORAGE.setItem('gs-creating-org', '1')
        }
    }, [ThumbnailPrev, Name])

    const [confirmDelete, setConfirmDelete] = useState(false)

    const openDeleteConfirm = () => {
        setConfirmDelete(true)
    }

    const closeDeleteConfirm = () => {
        setConfirmDelete(false)
    }

    const deleteWithoutSave = () => {
        deleteOrg()
    }

    useEffect(() => {
        console.log(polList)
    }, [polList])

    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
        htmlEditButton: {}
      };
    
      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
      ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}

            className="OrgsCreatePage page">

            <LocationModal
                setLocation={setLocation}
                visible={selectLocationModal}
                close={closeSelectLocation}
                coords={coords}
            />

            <PolygonModal
                data={editPolygon}
                orgId={createdId ? createdId : orgId}
                setPolList={setPolList}
                visible={selectPolyModal}
                close={closeSelectPoly}
            />
            <ConfirmModal
                visible={confirmDelete}
                close={closeDeleteConfirm}
                text={'Удалить организацию?'}
                cancel={deleteWithoutSave}
            />
            <PaymentEdit
                visible={payEditModal}
                onClose={() => setPayEditModal(false)}
                orgId={createdId ? createdId : orgId}
                currentList={pm}
                selected={selectedPay}
                setSelected={setSelectedPay}
                onEditPayment={editPay}
                onDeletePayment={deletePay}
            />
            <main className="Main">
                <div className="pageBody">
                    <div className="OrgsCreatePage__body pageBody-content">
                        <Row gutter={[25, 25]} justify={'space-between'}>
                            <Col span={12}>
                                <Row className='row-custom'>
                                    <div className="panel">
                                        <PlUpload
                                            style={{ height: 250, backgroundColor: '#F8F8F8' }}
                                            text={'Выбрать картинку'}
                                            id={'OrgPic'}
                                            accept={'.png, .jpeg, .bmp'}
                                            prev={ThumbnailPrev}
                                            onChange={uploadImage}
                                        />
                                    </div>
                                </Row>
                                <Row className='row-custom'>
                                    <Col span={24}>
                                        <div className="def-label">ID в системе</div>
                                        <div className="def-value">{orgId}</div>
                                    </Col>
                                </Row>
                                <Row className='row-custom'>
                                    {window.location.origin === 'https://ibazar.gscore.ru' ? null :
                                        switchCrm(settings,
                                            <Col span={24}>
                                                <Checkbox
                                                    id={'CanOverwrite'}
                                                    checked={CanOverwrite == '1'}
                                                    text={`Разрешить iiko перезаписывать организацию`}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCanOverwrite('1')
                                                        } else {
                                                            setCanOverwrite('0')
                                                        }
                                                    }}
                                                />
                                            </Col>,
                                            <Col span={24}>
                                                <Checkbox
                                                    id={'CanOverwrite'}
                                                    checked={CanOverwrite == '1'}
                                                    text={`Разрешить RKeeper перезаписывать организацию`}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCanOverwrite('1')
                                                        } else {
                                                            setCanOverwrite('0')
                                                        }
                                                    }}
                                                />
                                            </Col>,
                                            <Col span={24}>
                                                <Checkbox
                                                    id={'CanOverwrite'}
                                                    checked={CanOverwrite == '1'}
                                                    text={`Разрешить 1C перезаписывать организацию`}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCanOverwrite('1')
                                                        } else {
                                                            setCanOverwrite('0')
                                                        }
                                                    }}
                                                />
                                            </Col>,
                                            <Col span={24}>
                                                <Checkbox
                                                    id={'CanOverwrite'}
                                                    checked={CanOverwrite == '1'}
                                                    text={`Разрешить FrontPad перезаписывать организацию`}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCanOverwrite('1')
                                                        } else {
                                                            setCanOverwrite('0')
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        )
                                    }


                                </Row>
                                <Row className='row-custom'>
                                    <Checkbox
                                        onChange={e => {
                                            if (e.target.checked) {
                                                settransfer_to_call_center('1')
                                            } else {
                                                settransfer_to_call_center('0')
                                            }
                                        }}
                                        checked={transfer_to_call_center == '1'}
                                        id={'sendOrderKC'}
                                        text={'Отправлять заказы на КЦ'}
                                    />
                                </Row>
                                <Row className='row-custom'>
                                    {
                                        checkIsBao() ? (
                                            <Tabs defaultActiveKey="1" items={nameTabs} onChange={() => { }} style={{ width: '100%' }} />
                                        ) : nameTabs[0].children
                                    }
                                    {window.location.origin === 'https://uzgen.gscore.ru' ? <Tabs defaultActiveKey="1" items={nameTabs.filter(item => item.key === '1' || item.key === '3')} onChange={() => {}} style={{ width: '100%'}} /> : null}
                                </Row>
                                <Row className='row-custom'>
                                    <Text
                                        value={Description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        height={180}
                                        placeholder={'Описание'} />
                                </Row>
                                {window.location.origin === 'https://iiko.gscore.ru' || 
                                window.location.origin === 'https://mama.gscore.ru' || 
                                window.location.origin === 'https://test1.gscore.ru' || 
                                window.location.origin === 'https://paulbaker.gscore.ru'|| 
                                window.location.origin === 'https://darvin.gscore.ru'|| 
                                window.location.origin === 'https://iiko.gscore.ru' || 
                                window.location.origin === 'https://tigrus.gscore.ru' || 
                                window.location.origin === 'https://testjonny.gscore.ru' || 
                                window.location.origin === 'https://uzgen.gscore.ru' || 
                                window.location.origin === 'https://kekeev.gscore.ru' || 
                                window.location.origin === 'https://pizzeria.gscore.ru' || 
                                window.location.origin === 'https://osaka.gscore.ru' || 
                                window.location.origin === 'http://localhost:3000' ?
                                <Row className='row-custom'>
                                    <label style={{fontWeight: 700, marginBottom: '15px', marginTop: '15px'}}>Реквизиты</label>
                                {/* <Input
                                        maskType={String}
                                        value={Props}
                                        onChange={(e) => setProps(e.target.value)}
                                        placeholder={'Реквизиты'} /> */}
                                        <ReactQuill
                                            value={Props ? Props : null}
                                            onChange={(e) => {
                                                setProps(e);
                                            }}
                                            style={{ height: "200px", color: "black", marginBottom: '70px' }}
                                            formats={formats}
                                            modules={modules}
                                            />
                        </Row> : null
                                }
                                {
                                    checkIsTigrus() && (
                                        <Row className='row-custom'>
                                            <Input
                                                maskType={String}
                                                value={tag?.toString() ?? ''}
                                                onChange={(e) => setTag(e.target.value)}
                                                placeholder={'Тег'} />
                                        </Row>
                                    )
                                }
                                <Row className='row-custom'>
                                    <Input
                                        maskType={String}
                                        value={Address?.toString() ?? ''}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder={'Адрес'} />
                                </Row>
                                <Row className='row-custom'>
                                    <Input
                                        maskType={String}
                                        value={Phone?.toString() ?? ''}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder={'Телефон'} />
                                </Row>

                                {
                                    settings?.IsHaveIIko == '1' ? (
                                        <>
                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={IIkoIDTerminal?.toString() ?? ''}
                                                    onChange={(e) => setIIkoIDTerminal(e.target.value)}
                                                    placeholder={'ID кассовой станции'} />
                                            </Row>

                                        </>
                                    ) : null
                                }
                                <Row className='row-custom'>
                                    {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null :
                                        switchCrm(settings,
                                            <Input

                                                maskType={String}
                                                value={IIkoID?.toString() ?? ''}
                                                onChange={(e) => setIIkoID(e.target.value)}
                                                placeholder={`ID в iIko`} />,

                                            <Input

                                                maskType={String}
                                                value={IIkoID?.toString() ?? ''}
                                                onChange={(e) => setIIkoID(e.target.value)}
                                                placeholder={`ID в RKeeper`} />,
                                            <Input

                                                maskType={String}
                                                value={IIkoID?.toString() ?? ''}
                                                onChange={(e) => setIIkoID(e.target.value)}
                                                placeholder={`ID в 1C`} />,
                                            <Input

                                                maskType={String}
                                                value={IIkoID?.toString() ?? ''}
                                                onChange={(e) => setIIkoID(e.target.value)}
                                                placeholder={`ID в FrontPad`} />
                                        )
                                    }
                                </Row>

                                {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null :
                                    settings?.IsHaveRKeeper == '1' ? (
                                        <>
                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={RKeeperLogin?.toString() ?? ''}
                                                    onChange={(e) => setRKeeperLogin(e.target.value)}
                                                    placeholder={'Логин RKeeper'} />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={RKeeperIP?.toString() ?? ''}
                                                    onChange={(e) => setRKeeperIP(e.target.value)} placeholder={'IP RKeeper'} />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={RKeeperPort?.toString() ?? ''}
                                                    onChange={(e) => setRKeeperPort(e.target.value)}
                                                    placeholder={'Порт RKeeper'} />
                                            </Row>
                                        </>
                                    ) : null
                                }
                                {
                                    settings?.IsHavePrimehill == '1' ? (
                                        <Row className='row-custom'>
                                            <Input
                                                maskType={String}
                                                value={PrimehillToken?.toString() ?? ''} onChange={(e) => setPrimehillToken(e.target.value)} placeholder={'Токен PrimeHill'} />
                                        </Row>
                                    ) : null
                                }
                                <Row className='row-custom'>
                                    <Input
                                        scale={5}
                                        value={MinPriceForLocalSale?.toString()}
                                        onChange={(e) => setMinPriceForLocalSale(e.target.value)} placeholder={'Минимальная сумма заказа'} />
                                </Row>
                                {window.location.origin === 'https://ibazar.gscore.ru' ? null : <>
                                <Row className='row-custom'>
                                    <Input
                                        value={LocalOrderSale?.toString() ?? ''}
                                        onChange={(e) => setLocalOrderSale(e.target.value)} placeholder={'Скидка на самовывоз отсюда'} />
                                </Row>
                                <Row className='row-custom'>
                                    <Input
                                        value={TimeForSelfPickup?.toString() ?? ''}
                                        onChange={e => setTimeForSelfPickup(e.target.value)}
                                        placeholder="Время самовывоза"
                                    />
                                </Row> 
                                <Row className='row-custom'>
                                    <Checkbox
                                        checked={IsHaveLocalOrder == '1'}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIsHaveLocalOrder('1')
                                            } else {
                                                setIsHaveLocalOrder('0')
                                            }
                                        }}
                                        id={'IsHaveLocalOrder'}
                                        text={'Можно заказать отсюда'} />
                                </Row> </>}
                                <Row className='row-custom'>
                                    <div className="def-label">Выбор часового пояса</div>
                                    <DropCollapse
                                        // value={}
                                        value={Timezone}
                                        beforeIcon
                                        selectItem={selectTimezone}
                                        justify={'justifyLeft'}
                                        shadow
                                        list={timezonesList}
                                    />
                                </Row>
                                {/* <Row className='row-custom'>
                                    <Input
                                        maskType={String}
                                        value={Timezone}
                                        onChange={e => setTimezone(e.target.value)}
                                        placeholder={'Часовой пояс'}
                                    />
                                </Row> */}
                                <Row className='row-custom'>
                                    {/* <Input
                                        maskType={String}
                                        value={TimetableDescription}
                                        onChange={(e) => setTimetableDescription(e.target.value)}
                                        placeholder={'Описание времени работы'}
                                    /> */}
                                    <ReactQuill
                                            value={TimetableDescription ? TimetableDescription : null}
                                            onChange={(e) => {
                                                setTimetableDescription(e);
                                            }}
                                            style={{ height: "80px", color: "black", marginBottom: '70px' }}
                                            formats={formats}
                                            modules={modules}
                                            />
                                </Row>
                                {window.location.origin === 'https://ibazar.gscore.ru' ? null : 
                                <Row className='row-custom'>
                                    <TimeSelect
                                        save={saveTime}
                                        list={weekTimes}
                                    />
                                </Row>}
                                <Row className='row-custom'>
                                    <Checkbox
                                        checked={HideInApp == '1'}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setHideInApp('1')
                                            } else {
                                                setHideInApp('0')
                                            }
                                        }}
                                        id="HideInApp"
                                        text={'Скрыть в приложении'}
                                    />
                                </Row>
                                <Row className='row-custom'>
                                    <Checkbox
                                        checked={IsNeedToNotify == '1'}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setIsNeedToNotify('1')
                                            } else {
                                                setIsNeedToNotify('0')
                                            }
                                        }}
                                        id={'3'}
                                        text={'Уведомления в телеграм-боте и на E-Mail'} />
                                </Row>
                                {
                                    IsNeedToNotify == '1' ? (
                                        <>
                                            <Row className='row-custom'>
                                                <Input maskType={String} value={BotToken?.toString() ?? ''} onChange={(e) => setBotToken(e.target.value)} placeholder={'API-key бота'} />
                                            </Row>

                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={BotChatID?.toString() ?? ''}
                                                    type={'text'}
                                                    onChange={(e) => setBotChatID(e.target.value)}
                                                    placeholder={'Chat ID бота'} />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Input
                                                    maskType={String}
                                                    value={Email?.toString() ?? ''}
                                                    type={'email'}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder={'Email'} />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Checkbox
                                                    checked={NotifyWhenNewOrder == '1'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNotifyWhenNewOrder('1')
                                                        } else {
                                                            setNotifyWhenNewOrder('0')
                                                        }
                                                    }}
                                                    id={'NotifyWhenNewOrder'}
                                                    text={'Уведомлять о новых заказах'}
                                                />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Checkbox
                                                    checked={NotifyWhenNewReservation == '1'}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setNotifyWhenNewReservation('1')
                                                        } else {
                                                            setNotifyWhenNewReservation('0')
                                                        }
                                                    }}
                                                    id="NotifyWhenNewReservation"
                                                    text={'Уведомлять о новых бронях'}
                                                />
                                            </Row>
                                            {/* {
                                                settings?.IsHaveIIko == '1' || settings?.IsHaveIIko == '1' ? (
                                                    <Row className='row-custom'>
                                                        <Checkbox 
                                                            checked={NotifyWhenIIkoErrors == '1'} 
                                                            onChange={(e) => {
                                                                if(e.target.checked) {
                                                                    setNotifyWhenIIkoErrors('1')
                                                                } else {
                                                                    setNotifyWhenIIkoErrors('0')
                                                                }
                                                            }} 
                                                            id={'NotifyWhenIIkoErrors'} 
                                                            text={
                                                                settings?.IsHaveIIko == '1' ? 
                                                                'Уведомлять об ошибках Iiko' : 
                                                                'Уведомлять об ошибках RKeeper'
                                                            }
                                                            />
                                                    </Row> 
                                                ) : null
                                            } */}
                                            {
                                                switchCrm(settings,
                                                    <Row className='row-custom'>
                                                        <Checkbox
                                                            checked={NotifyWhenIIkoErrors == '1'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setNotifyWhenIIkoErrors('1')
                                                                } else {
                                                                    setNotifyWhenIIkoErrors('0')
                                                                }
                                                            }}
                                                            id={'NotifyWhenIIkoErrors'}
                                                            text={'Уведомлять об ошибках Iiko'}
                                                        />
                                                    </Row>,
                                                    <Row className='row-custom'>
                                                        <Checkbox
                                                            checked={NotifyWhenIIkoErrors == '1'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setNotifyWhenIIkoErrors('1')
                                                                } else {
                                                                    setNotifyWhenIIkoErrors('0')
                                                                }
                                                            }}
                                                            id={'NotifyWhenIIkoErrors'}
                                                            text={'Уведомлять об ошибках RKeeper'}
                                                        />
                                                    </Row>,
                                                    <Row className='row-custom'>
                                                        <Checkbox
                                                            checked={NotifyWhenIIkoErrors == '1'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setNotifyWhenIIkoErrors('1')
                                                                } else {
                                                                    setNotifyWhenIIkoErrors('0')
                                                                }
                                                            }}
                                                            id={'NotifyWhenIIkoErrors'}
                                                            text={'Уведомлять об ошибках 1C'}
                                                        />
                                                    </Row>,
                                                    <Row className='row-custom'>
                                                        <Checkbox
                                                            checked={NotifyWhenIIkoErrors == '1'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setNotifyWhenIIkoErrors('1')
                                                                } else {
                                                                    setNotifyWhenIIkoErrors('0')
                                                                }
                                                            }}
                                                            id={'NotifyWhenIIkoErrors'}
                                                            text={'Уведомлять об ошибках FrontPad'}
                                                        />
                                                    </Row>
                                                )
                                            }

                                            <Row className='row-custom'>
                                                <Checkbox
                                                    checked={NotifyWhenOrderChanges == '1'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNotifyWhenOrderChanges('1')
                                                        } else {
                                                            setNotifyWhenOrderChanges('0')
                                                        }
                                                    }}
                                                    id={'NotifyWhenOrderChanges'}
                                                    text={'Уведомлять об изменениях в заказах'}
                                                />
                                            </Row>
                                        </>
                                    ) : null
                                }

                                <Row className='row-custom'>
                                    <Button
                                        styles={{ width: '100%' }}
                                        onClick={orgSubmit}
                                        disabled={!Name}
                                        load={saveLoad}
                                        before={<SaveIcon size={20} color={'#fff'} />}
                                        text={'Сохранить'}
                                        type={'button'}
                                        justify={'flex-start'} />
                                    {
                                        orgId ? (
                                            <Button
                                                styles={{ width: '100%', marginTop: 10 }}
                                                onClick={openDeleteConfirm}
                                                disabled={false}
                                                load={delLoad}
                                                before={<BsTrash size={20} />}
                                                text={'Удалить'}
                                                type={'button'}
                                                variant={'danger'}
                                                justify={'flex-start'} />
                                        ) : null
                                    }
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row className='row-custom'>
                                    <Button
                                        onClick={() => nav(`/catalog?p=Каталог&org=${orgId}`)}
                                        styles={{ width: '100%' }}
                                        text={'Перейти в каталог'}
                                        variant={'default'}
                                    />
                                </Row>
                                <Row className='row-custom'>
                                    <div className="panel">
                                        <div className="panel-label">
                                            Местоположение на карте
                                        </div>
                                        {
                                            coords ? (
                                                <div style={{ height: 250 }} onClick={openSelectLocation}>
                                                    <MapMarker
                                                        id="location-map"
                                                        readOnly
                                                        coords={coords}
                                                    />

                                                </div>

                                            ) : (
                                                <Pl
                                                    onClick={openSelectLocation}
                                                    style={{ height: 200, backgroundColor: '#F8F8F8' }}
                                                    text={'Выбрать на карте'} />
                                            )
                                        }
                                    </div>
                                </Row>
                                {
                                    createdId || orgId ? (
                                        <>
                                            <Row gutter={[10, 10]} className='row-custom'>
                                                <Col span={12}>
                                                    <Checkbox
                                                        onChange={e => {
                                                            if (e.target.checked) {
                                                                setIsHaveDelivery('1')
                                                            } else {
                                                                setIsHaveDelivery('0')
                                                            }
                                                        }}
                                                        checked={IsHaveDelivery == '1'}
                                                        id={'IsHaveDelivery'}
                                                        text={'Есть доставка'} />
                                                </Col>
                                                {
                                                    IsHaveDelivery == '1' ? (
                                                        <Col span={12}>
                                                            <UploadKml
                                                                openMap={openSelectPoly}
                                                                updatePolList={updatePolList}
                                                            />
                                                        </Col>
                                                    ) : null
                                                }


                                            </Row>
                                            {
                                                IsHaveDelivery == '1' ? (
                                                    <Row className='row-custom' gutter={[30, 30]}>
                                                        {
                                                            polList && polList.length > 0 ? (
                                                                polList.map((item, index) => (
                                                                    <Col span={12} key={index}>
                                                                        <div onClick={() => {
                                                                            editPolygonFunc({ ...item })
                                                                        }} className="panel" style={{ height: 275 }}>
                                                                            <MapPolygonPic
                                                                                name={item?.Name}
                                                                                polygonCoords={item?.Coordinates}
                                                                                color={item?.Color}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                ))
                                                            ) : null
                                                        }
                                                        <Col span={12} >
                                                            <div className="panel" style={{ height: 275 }}>
                                                                <Pl
                                                                    onClick={openSelectPoly}
                                                                    text={'Добавить полигон доставки'} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                            <Row className='row-custom'>
                                                {
                                                    pm && pm.length > 0 ? (
                                                        pm.map((item, index) => (
                                                            <PayMethods
                                                                openEditModal={() => setPayEditModal(true)}
                                                                key={index}
                                                                selected={item}
                                                                list={paymethods}
                                                                onCashbackChange={editPay}
                                                                onChange={addPay}
                                                                onDelete={deletePay}
                                                                onSelect={setSelectedPay}
                                                            />
                                                        ))
                                                    ) : null
                                                }
                                                {
                                                    pm?.length < 4 ? (
                                                        <div className="panel" style={{ padding: 0 }}>
                                                            <Pl onClick={addPayMethods} text={'Добавить способ оплаты'} />
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    window.location.origin === 'http://localhost:3000' ? <Select
                                                    style={{
                                                      width: '100%',
                                                      fontSize: '22px',
                                                      textAlign: 'center',
                                                      fontWeight: '700',
                                                      color: '#989898;',
                                                      border: 'none',
                                                      borderRadius: '20px',
                                                    }}
                                                    placeholder="Добавить способ оплаты"
                                                    onChange={handleSelectPayment}
                                                    options={[{label: 'Оплата наличными', value: 'Оплата наличными'}, {label: 'Оплата по карте в приложении', value: 'Оплата по карте в приложении'}, {label: 'Оплата по карте при получении', value: 'Оплата по карте при получении'}, {label: 'Оплата бонусами', value: 'Оплата бонусами'}]}
                                                  /> : null
                                                }
                                            </Row>
                                        </>
                                    ) : null
                                }

                                <Row className='row-custom'>
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setHavePreorder('1')
                                            } else {
                                                setHavePreorder('0')
                                                setCountTimeStepsPreorder('')
                                                setTimeStep('')
                                            }
                                        }}
                                        checked={HavePreorder == '1'}
                                        id={'preOrder'}
                                        text={'Есть предзаказ'} />
                                </Row>
                                {
                                    HavePreorder == '1' ? (
                                        <>
                                            <Row className='row-custom'>
                                                <Input
                                                    value={TimeStep?.toString() ?? ''}
                                                    onChange={(e) => setTimeStep(e.target.value)}
                                                    placeholder={'Шаг выбора времени предзаказа (в минутах)'} />
                                            </Row>
                                            <Row className='row-custom'>
                                                <Input
                                                    value={CountTimeStepsPreorder?.toString() ?? ''}
                                                    onChange={(e) => setCountTimeStepsPreorder(e.target.value)}
                                                    placeholder={'Максимальное количество шагов'} />
                                            </Row>
                                        </>
                                    ) : null
                                }

                                {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null :
                                    checkDomain(<>
                                        <Row className='row-custom'>
                                            <Checkbox
                                                checked={HaveReservation == '1'}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setHaveReservation('1')
                                                    } else {
                                                        setHaveReservation('0')
                                                        setCountTimeStepsReservation('')
                                                        setTimeStepReservation('')
                                                    }
                                                }}
                                                id={HaveReservation}
                                                text={'Есть бронирование столика'} />
                                        </Row>
                                        {
                                            HaveReservation == '1' ? (
                                                <>
                                                    <Row className='row-custom'>
                                                        <Input
                                                            value={TimeStepReservation?.toString() ?? ''}
                                                            onChange={(e) => setTimeStepReservation(e.target.value)}
                                                            placeholder={'Шаг выбора времени бронирования (в минутах)'} />
                                                    </Row>
                                                    <Row className='row-custom'>
                                                        <Input
                                                            value={CountTimeStepsReservation?.toString() ?? ''}
                                                            onChange={(e) => setCountTimeStepsReservation(e.target.value)}
                                                            placeholder={'Максимальное количество шагов'} />
                                                    </Row>
                                                </>
                                            ) : null
                                        }
                                    </>, null)
                                }
                                {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://macarons.gscore.ru' ? null : 
                                <Row className='row-custom'>
                                <TimeSelect
                                    save={saveReservationTime}
                                    list={weekReservationTimes}
                                    />
                                </Row>}
                                    {/* {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null : checkDom('https://tigrus.gscore.ru', )} */}


                                 

                                <Row className='row-custom'>
                                    <div className="def-label">Выбор платежной системы</div>
                                    <DropCollapse
                                        // value={}
                                        value={PaymentSystemType}
                                        beforeIcon
                                        selectItem={selectPaySystem}
                                        justify={'justifyLeft'}
                                        // shadow
                                        list={paymentTypes}
                                    />
                                </Row>
                                {/* {window.location.origin === 'https://macarons.gscore.ru' || window.location.origin === 'http://localhost:3000' ? 
                                <>
                            <Row className="row-custom">
                                <div className="def-label">Бонусы</div>
                                <Input
                                    value={MaxBonusesPercent}
                                    onChange={e => setMaxBonusesPercent(e.target.value)}
                                    maskType={String}
                                    placeholder={'Процент бонусов для оплаты'}/>
                                </Row>
                                <Row className="row-custom">
                                    <Input 
                                        value={MaxBonusesPayment}
                                        onChange={e => setMaxBonusesPayment(e.target.value)}
                                        maskType={String}
                                        placeholder={'Максимальное количество бонусов для оплаты'}/>
                                </Row>
                                <Row className="row-custom">
                                    <Checkbox 
                                        checked={bonusDel == '1'}
                                        onChange={e => e.target.checked ? setBonusDel('1') : setBonusDel('0')}
                                        text={'Оплата бонусами для доставки'} 
                                        id="1"/>
                                </Row>
                                <Row className="row-custom">
                                    <Checkbox 
                                        checked={bonusLocal == '1'}
                                        onChange={e => e.target.checked ? setBonusLocal('1') : setBonusLocal('0')}
                                        text={'Оплата бонусами для самовывоза'} 
                                        id="2"
                                        />
                                </Row>
                                <Row className="row-custom">
                                    <Input 
                                        maskType={String}
                                        value={OrderBonusesGetPercent}
                                        onChange={e => setOrderBonusesGetPercent(e.target.value)}
                                        placeholder={'Процент получаемых бонусов'}/>
                                </Row>
                                <Row className="row-custom">
                                    <Input 
                                        value={OrderBonusesGetMax}
                                        onChange={e => setOrderBonusesGetMax(e.target.value)}
                                        maskType={String}
                                        placeholder={'Максимальный процент получаемых бонусов'}/>
                                </Row>
                                <Row className="row-custom">
                                    <Checkbox
                                        checked={payBonusDel == '1'}
                                        onChange={e => e.target.checked ? setPayBonusDel('1') : setPayBonusDel('0')}
                                        text={'Получение бонусов при доставке'} 
                                        id="5"/>
                                </Row>
                                <Row className="row-custom">
                                    <Checkbox
                                        checked={payBonusLocal == '1'}
                                        onChange={e => e.target.checked ? setPayBonusLocal('1') : setPayBonusLocal('0')}
                                        text={'Получение бонусов при самовывозе'} 
                                        id="4"/>
                                </Row>
                                <Row className="row-custom">
                                    <BasketOnlinePay
                                        value={OnlyPayOnlineWhen}
                                        setValue={setOnlyPayOnlineWhen}
                                        />
                                </Row></> : null} */}

                                {window.location.origin === 'https://iiko.gscore.ru' || 
                                window.location.origin === 'https://mama.gscore.ru' || 
                                window.location.origin === 'https://test1.gscore.ru' || 
                                window.location.origin === 'https://paulbaker.gscore.ru'|| 
                                window.location.origin === 'https://darvin.gscore.ru'|| 
                                window.location.origin === 'https://iiko.gscore.ru' || 
                                window.location.origin === 'https://tigrus.gscore.ru' || 
                                window.location.origin === 'https://testjonny.gscore.ru' || 
                                window.location.origin === 'https://uzgen.gscore.ru' || 
                                window.location.origin === 'https://kekeev.gscore.ru' || 
                                window.location.origin === 'https://pizzeria.gscore.ru' || 
                                window.location.origin === 'https://osaka.gscore.ru' || 
                                window.location.origin === 'http://localhost:3000' ? 
                                    <Row style={{display: 'flex', flexDirection: 'column'}} className='row-custom'>
                                    <div className="def-label">Мероприятия</div>
                                    <Button styles={{marginBottom: '15px'}} text={"Мастер-классы"} onClick= {showEventsMasterModal}></Button>
                                    <Button text={"Шоу-программа"} onClick={showEventsShowModal}></Button>
                                    {/* master modal */}
                                    <Modal
                                        open={isEventsMasterModalOpen}
                                        title="Список мастер-классов"
                                        onCancel={handleEventsMasterModalCancel}
                                        footer={[]}
                                        width={1200}
                                    >
                                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px'}}>
                                            {masterModalData ? masterModalData.map(item => (
                                                <div
                                                style={{
                                                border: "2px solid gray",
                                                padding: 12,
                                                borderRadius: "12px",
                                                position: "relative",
                                                cursor: 'pointer'
                                                }}
                                                onClick={(event) => {
                                                    // if (event.target === event.currentTarget) {
                                                        showEditEventMasterModal(item.id, item.text, item.date);
                                                    // }
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: -12,
                                                        cursor: "pointer",
                                                        right: -12,
                                                        background: "#DC143C",
                                                        height: 26,
                                                        width: 26,
                                                        borderRadius: "50%",
                                                        color: "white",
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                        lineHeight: "24px",
                                                    }} onClick={() => deleteEvent(item.id)}
                                                    >
                                                    x
                                                    </div>
                                                    <h4 style={{fontWeight: 600, fontSize: 20}}>{item.text}</h4>
                                                    {item.date}
                                            </div>
                                            )) : null}
                                            <Modal
                                                open={isEditEventMasterModalOpen}
                                                title="Редактировать мастер-класс"
                                                onCancel={handleEditEventMasterModalCancel}
                                                footer={[]}
                                                width={580}
                                                >
                                                <div className="QAPage__item">
                                                    
                                                            <div className="QAPage__item-label">Дата</div>
                                                            <div style={{display: 'felx', flexDirection: 'column'}}>
                                                                <p style={{textAlign: 'right'}}>{eventDate}</p>
                                                            
                                                            <DatePicker
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            disabledDate={disabledDate}
                                                            showTime={{
                                                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                                                            }}
                                                            onChange={(obj, string) => {setEventLocalDate(string); setEventDate(string)}}
                                                            />
                                                            </div>
                                                            
                                                            </div>
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Название мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventName(e.target.value)}
                                                                value={eventName?.toString() ?? ''}
                                                            />
                                                            </div>
                                                {/* <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Тип мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalType(e.target.value)}
                                                                value={eventLocalType}
                                                            />
                                                            </div> */}
                                                            <div className="QAPage__modal-wrapper">
                                                    <Button
                                                    key="back"
                                                    onClick={handleEditEventMasterModalCancel}
                                                    text={"Отмена"}
                                                    variant={"danger"}
                                                    />
                                                    <Button
                                                    key="back"
                                                    onClick={editMasterEvent}
                                                    text={"Сохранить"}
                                                    />
                                                </div>
                                                </Modal>
                                            <div
                                                style={{
                                                border: "2px dashed gray",
                                                padding: 12,
                                                borderRadius: "12px",
                                                position: "relative",
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: 600,
                                                fontSize: 21,
                                                backgroundColor: '#eee'
                                                }}
                                                onClick={showAddEventMasterModal}
                                            >
                                                    Добавить мастер-класс
                                            </div>
                                            <Modal
                                                open={isAddEventMasterModalOpen}
                                                title="Добавить мастер-класс"
                                                onCancel={handleAddEventMasterModalCancel}
                                                footer={[]}
                                                width={580}
                                                >
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Дата</div>
                                                            <DatePicker
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            disabledDate={disabledDate}
                                                            showTime={{
                                                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                                                            }}
                                                            onChange={(obj, string) => setEventLocalDate(string)}
                                                            />
                                                            </div>
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Название мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalName(e.target.value)}
                                                                value={eventLocalName?.toString() ?? ''}
                                                            />
                                                            </div>
                                                {/* <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Тип мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalType(e.target.value)}
                                                                value={eventLocalType}
                                                            />
                                                            </div> */}
                                                            <div className="QAPage__modal-wrapper">
                                                    <Button
                                                    key="back"
                                                    onClick={handleAddEventMasterModalCancel}
                                                    text={"Отмена"}
                                                    variant={"danger"}
                                                    />
                                                    <Button
                                                    key="back"
                                                    onClick={addMasterEvent}
                                                    text={"Сохранить"}
                                                    />
                                                </div>
                                                </Modal>
                                        </div>
                                        <Btn
                                        onClick={handleEventsMasterModalCancel}
                                        danger
                                        type={"primary"}
                                        style={{ marginLeft: "auto", display: "block", marginTop: '20px' }}
                                        >
                                        Закрыть
                                        </Btn>
                                    </Modal>
                                    <Modal
                                        open={isEventsShowModalOpen}
                                        title="Список шоу-программ"
                                        onCancel={handleEventsShowModalCancel}
                                        footer={[]}
                                        width={1200}
                                    >
                                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px'}}>
                                            {showModalData ? showModalData.map(item => (
                                                <div
                                                style={{
                                                border: "2px solid gray",
                                                padding: 12,
                                                borderRadius: "12px",
                                                position: "relative",
                                                cursor: 'pointer'
                                                }}
                                                onClick={(event) => {
                                                    // if (event.target === event.currentTarget) {
                                                        showEditEventShowModal(item.id, item.text, item.date);
                                                    // }
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: -12,
                                                        cursor: "pointer",
                                                        right: -12,
                                                        background: "#DC143C",
                                                        height: 26,
                                                        width: 26,
                                                        borderRadius: "50%",
                                                        color: "white",
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                        lineHeight: "24px",
                                                    }} onClick={(e) => {deleteEvent(item.id);}} 
                                                    >
                                                    x
                                                    </div>
                                                    <h4 style={{fontWeight: 600, fontSize: 20}}>{item.text}</h4>
                                                    {item.date}
                                            </div>
                                            )) : null}
                                            <Modal
                                                open={isEditEventShowModalOpen}
                                                title="Редактировать шоу-программу"
                                                onCancel={handleEditEventShowModalCancel}
                                                footer={[]}
                                                width={580}
                                                >
                                                <div className="QAPage__item">
                                                    
                                                            <div className="QAPage__item-label">Дата</div>
                                                            <div style={{display: 'felx', flexDirection: 'column'}}>
                                                                <p style={{textAlign: 'right'}}>{eventDate}</p>
                                                            
                                                            <DatePicker
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            disabledDate={disabledDate}
                                                            showTime={{
                                                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                                                            }}
                                                            onChange={(obj, string) => {setEventLocalDate(string); setEventDate(string)}}
                                                            />
                                                            </div>
                                                            
                                                            </div>
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Название мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventName(e.target.value)}
                                                                value={eventName?.toString() ?? ''}
                                                            />
                                                            </div>
                                                {/* <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Тип мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalType(e.target.value)}
                                                                value={eventLocalType}
                                                            />
                                                            </div> */}
                                                            <div className="QAPage__modal-wrapper">
                                                    <Button
                                                    key="back"
                                                    onClick={handleEditEventShowModalCancel}
                                                    text={"Отмена"}
                                                    variant={"danger"}
                                                    />
                                                    <Button
                                                    key="back"
                                                    onClick={editShowEvent}
                                                    text={"Сохранить"}
                                                    />
                                                </div>
                                                </Modal>
                                            <div
                                                style={{
                                                border: "2px dashed gray",
                                                padding: 12,
                                                borderRadius: "12px",
                                                position: "relative",
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: 600,
                                                fontSize: 21,
                                                backgroundColor: '#eee'
                                                }}
                                                onClick={showAddEventShowModal}
                                            >
                                                    Добавить шоу-программу
                                            </div>
                                            <Modal
                                                open={isAddEventShowModalOpen}
                                                title="Добавить шоу-программу"
                                                onCancel={handleAddEventShowModalCancel}
                                                footer={[]}
                                                width={580}
                                                >
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Дата</div>
                                                            <DatePicker
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            disabledDate={disabledDate}
                                                            showTime={{
                                                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                                                            }}
                                                            onChange={(obj, string) => setEventLocalDate(string)}
                                                            />
                                                            </div>
                                                <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Название мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalName(e.target.value)}
                                                                value={eventLocalName?.toString() ?? ''}
                                                            />
                                                            </div>
                                                {/* <div className="QAPage__item">
                                                            <div className="QAPage__item-label">Тип мероприятия</div>
                                                            <Input
                                                            style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}}
                                                                maskType={String}
                                                                shadow
                                                                onChange={(e) => setEventLocalType(e.target.value)}
                                                                value={eventLocalType}
                                                            />
                                                            </div> */}
                                                            <div className="QAPage__modal-wrapper">
                                                    <Button
                                                    key="back"
                                                    onClick={handleAddEventShowModalCancel}
                                                    text={"Отмена"}
                                                    variant={"danger"}
                                                    />
                                                    <Button
                                                    key="back"
                                                    onClick={addShowEvent}
                                                    text={"Сохранить"}
                                                    />
                                                </div>
                                                </Modal>
                                        </div>
                                        <Btn
                                        onClick={handleEventsShowModalCancel}
                                        danger
                                        type={"primary"}
                                        style={{ marginLeft: "auto", display: "block", marginTop: '20px' }}
                                        >
                                        Закрыть
                                        </Btn>
                                    </Modal>

                                </Row>
                                : null}
                                
                            </Col>
                        </Row>
                    </div>
                </div>
            </main>
        </motion.div>
    )
}

export default OrgsCreatePage;